import { ReactNativePostMessagesEnum } from '@webstore-monorepo/shared/enums';

/* eslint no-console: 0 */
enum ReactNativeMessageTypeEnum {
  BACK_BUTTON_PRESS = 'BACK_BUTTON_PRESS',
  REQUEST_APPLE_PAY_CARD_NONCE_SUCCESS = 'REQUEST_APPLE_PAY_CARD_NONCE_SUCCESS',
  REQUEST_GOOGLE_PAY_CARD_NONCE_SUCCESS = 'REQUEST_GOOGLE_PAY_CARD_NONCE_SUCCESS',
  IS_SINGLE_STORE = 'IS_SINGLE_STORE',
  PLATFORM_OS = 'PLATFORM_OS',
}

export interface ReactNativeWebViewSubscriberInterface {
  onMessage(action: { type: ReactNativeMessageTypeEnum; payload?: any }): void;
}

function handleBackButtonPress() {
  const deepPath: RegExp = /\/menu\/.+/;

  if (deepPath.exec(window.location.href)) {
    // Initial path was a deep path. Can't navigate back !!!
    if (window.history.length === 1) {
      window.location.href = window.location.href.replace(deepPath, '/menu');
    } else {
      window.history.back();
    }
    return;
  }

  /**
   * Send message to React Native app when webstore is in WebView of React Native.
   * This message is handled in mobile-webstore project with WebView.onMessage function.
   * Before changing discuss with React Native team.
   */
  if (window.ReactNativeWebView) {
    /**
     * @todo refactor to send JSON.stringify({type: 'BACK_TO_LOCATIONS', payload: undefined}); | Breaking cases for ReactNative app.
     */
    window.ReactNativeWebView.postMessage(ReactNativePostMessagesEnum.BACK_TO_LOCATIONS);
  }
}

function onMessage(action: { type: ReactNativeMessageTypeEnum; payload?: any } | string) {
  function isStringAction(a: any): a is string {
    return typeof a === 'string';
  }
  /**
   * Backward compatability for Android message
   * @deprecated
   */
  if (isStringAction(action) && ReactNativeMessageTypeEnum.BACK_BUTTON_PRESS === action) {
    handleBackButtonPress();
    return;
  }

  if (!isStringAction(action)) {
    const { merchant, newStore } = window.SSRContext ?? {};
    switch (action.type) {
      case ReactNativeMessageTypeEnum.BACK_BUTTON_PRESS:
        handleBackButtonPress();
        break;
      case ReactNativeMessageTypeEnum.REQUEST_APPLE_PAY_CARD_NONCE_SUCCESS:
      case ReactNativeMessageTypeEnum.REQUEST_GOOGLE_PAY_CARD_NONCE_SUCCESS:
        window.postMessage(action, '*');
        break;
      case ReactNativeMessageTypeEnum.IS_SINGLE_STORE:
        window.ReactNativeHideBackToLocations = !!action.payload;
        break;
      case ReactNativeMessageTypeEnum.PLATFORM_OS:
        window.ReactNativePlatformOS = action.payload;
        break;
      default:
        console.error(`Unknown action.type: ${action.type}`);
    }
  }
}

export const ReactNativeWebViewSubscriber: ReactNativeWebViewSubscriberInterface = {
  onMessage,
};
